<template>
  <div class="admins-page">
    <a-row type="flex" justify="space-between">
      <a-col :lg="10" :md="10" :xs="24">
        <h2 class="list-title">
          <font-awesome-icon
            class="anticon list-icon"
            :icon="['fas', 'users']"
          />
          <span>{{ labels.title }}</span>
        </h2>
        <p class="list-subtitle">{{ labels.subtitle }}</p>
      </a-col>
      <a-col :lg="14" :md="14" :xs="24">
        <a-row
          type="flex"
          justify="end"
          :gutter="16"
          :style="{
            alignItems: 'flex-end',
            marginTop: '25px',
            marginBottom: '10px',
          }"
        >
          <a-col :lg="18" :md="18" :sm="20" :xs="16">
            <a-input-search
              :placeholder="labels.placeholderSearch"
              style="width: 100%"
              @search="handleSearch"
              allowClear
            />
          </a-col>
          <a-col
            v-if="canCreate"
            :lg="6"
            :md="6"
            :sm="4"
            :xs="8"
            :style="{ textAlign: 'right' }"
          >
            <router-link :to="{ name: 'admin.admins.create' }">
              <a-button type="default" ghost icon="user-add" class="add-button">
                <span>{{ labels.add }}</span>
              </a-button>
            </router-link>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <StandardTable
          ref="standardTable"
          :fetchService="fetchAdmins"
          :columns="columns"
          :locale="locale"
          :handleShowTotal="handleShowTotal"
          :scroll="{ x: true }"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import labels from "@/utils/labels";
import StandardTable from "../../../components/core/table/StandardTable.vue";
import { mapActions, mapGetters } from "vuex";
import { adminPageTitle, hasRoles, roles } from "../../../utils/utils";
export default {
  components: { StandardTable },
  name: "AdminList",
  metaInfo: {
    title: adminPageTitle("Usuarios"),
  },
  data() {
    return {
      labels: labels.admins,
      search: "",
    };
  },
  methods: {
    ...mapActions("admins", ["fetchAdmins", "deleteAdmin"]),
    handleReloadTable() {
      let filtersTable = this.$refs.standardTable.filters;
      let result = { ...filtersTable };
      this.$refs.standardTable.handleFilters(result);
    },
    handleSearch(search) {
      this.$refs.standardTable.handleSearch(search);
    },
    handleShowTotal(total, range) {
      return `${range[0]}-${range[1]} de ${total}`;
    },
    confirmDelete(id) {
      this.$confirm({
        title: this.labels.confirm.title,
        content: this.labels.confirm.subtitle,
        okText: this.labels.confirm.confirmButtonText,
        okType: "danger",
        icon: "delete",
        cancelText: this.labels.confirm.cancelButtonText,
        onOk: async () => {
          try {
            await this.deleteAdmin(id);
            this.$message.success(labels.deletedSuccess);
            this.handleReloadTable();
          } catch (error) {
            this.$message.error(
              error?.response?.data?.message ||
                error?.response?.message ||
                error.message ||
                labels.deleteError
            );
          }
        },
      });
    },
  },
  computed: {
    ...mapGetters("adminAuth", ["getLoggedUser"]),
    canCreate() {
      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [roles.admin.ROLE_ADMINS_CREATE]);
    },
    canDelete() {
      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [roles.admin.ROLE_ADMINS_DELETE]);
    },
    locale() {
      return {
        emptyText: this.labels.table.emptyText,
      };
    },
    columns() {
      return [
        {
          title: this.labels.table.columns.name,
          dataIndex: "name",
          key: "name",
          sorter: true,
        },
        {
          title: this.labels.table.columns.email,
          dataIndex: "email",
          key: "email",
          sorter: true,
        },
        {
          title: this.labels.table.columns.createdAt,
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          customRender: (text) => {
            return this.$moment(text).format("DD/MM/YYYY H:mm:ss a");
          },
        },
        {
          title: this.labels.table.columns.updatedAt,
          dataIndex: "updated_at",
          key: "updated_at",
          sorter: true,
          xs: 24,
          customRender: (text) => {
            return this.$moment(text).format("DD/MM/YYYY H:mm:ss a");
          },
        },
        {
          title: this.labels.table.columns.status.title,
          dataIndex: "status",
          key: "status",
          sorter: true,
          customRender: (text) => {
            return (
              <a-tag color={text ? "green" : "red"}>
                {text
                  ? this.labels.table.columns.status.active
                  : this.labels.table.columns.status.inactive}
              </a-tag>
            );
          },
        },
        {
          title: this.labels.table.columns.action.title,
          dataIndex: "action",
          key: "action",
          width: "120px",
          align: "center",
          customRender: (text, record) => {
            return (
              <div class="action-buttons">
                <router-link
                  to={{
                    name: "admin.admins.edit",
                    params: { id: record.id },
                  }}
                  title={this.labels.table.columns.action.edit}
                >
                  <a-button
                    type="link"
                    icon="edit"
                    class="action-button-edit"
                  />
                </router-link>
                {this.canDelete && (
                  <a-button
                    type="link"
                    icon="delete"
                    class="action-button-delete"
                    onClick={() => this.confirmDelete(record.id)}
                    title={this.labels.table.columns.action.delete}
                  />
                )}
              </div>
            );
          },
        },
      ];
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.admins-page {
  .list {
    &-title {
      margin-bottom: 10px;
      .list-icon {
        margin-right: 10px;
      }
    }
    &-subtitle {
      margin-bottom: 10px;
    }
  }
  .add-button {
    color: color(primary);
    border-color: color(primary);
  }
  .action-buttons {
    display: flex;
    justify-content: space-between;
    .action-button-edit {
      color: color(primary);
    }
    .action-button-delete {
      color: color(danger);
    }
  }
}
</style>
